import { createGetKcContext } from "keycloakify/login";

export type KcContextExtension =
    // WARNING: It's important to keep in sync the extraThemeProperties declared in the package.json and this type definition.
    { pageId: "info.ftl" };

//NOTE: In most of the cases you do not need to overload the KcContext, you can
// just call createGetKcContext(...) without type arguments.
// You want to overload the KcContext only if:
// - You have custom plugins that add some values to the context (like https://github.com/micedre/keycloak-mail-whitelisting that adds authorizedMailDomains)
// - You want to add support for extra pages that are not yey featured by default, see: https://docs.keycloakify.dev/contributing#adding-support-for-a-new-page
export const { getKcContext } = createGetKcContext<KcContextExtension>({
    mockData: [
        {
            pageId: "info.ftl",
            locale: {
                //When we test the login page we do it in russian
                currentLanguageTag: "ru",
            },
            //Uncomment the following line for showing an Error message
            // message: { type: "error", summary: "This is an error asdasdasdas dasdasd1qdwq3ea sdasdqw3e1" },

            //Uncomment the following line for showing an Error message
            // message: { type: "warning", summary: "This is a warning message" },

            //Uncomment the following line for showing an Info message
            // message: {type: "info", summary: "Info msg"},

            //Uncomment the following line for showing an Error message
            // message: { type: "success", summary: "This is a success message" },

            //Uncomment the following line for hiding the Alert message
            message: undefined,
            realm: {
                internationalizationEnabled: false,
            },
        },
    ],
});

export const { kcContext } = getKcContext({
    // Uncomment to test the login page for development.
    mockPageId: 'login-reset-password.ftl'
    // XXX когда вводим yarn start и запускаем приложение, сможем посмотреть выбранную страницу на локальном дев сервере
});

export type KcContext = NonNullable<
    ReturnType<typeof getKcContext>["kcContext"]
>;
